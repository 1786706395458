import tw from "twin.macro"
import React from "react"
import SEO from "../components/seo"

import github from "../images/github.svg"

const logos = [
  {
    name: "gatsby",
    logo: require("../images/gatsby.svg"),
  },
  {
    name: "tailwind",
    logo: require("../images/tailwind.svg"),
  },
  {
    name: "emotion",
    logo: require("../images/emotion.png"),
  },
]

const Wrapper = tw.div`
  flex items-center justify-center flex-col h-screen
`

const Main = tw.div`
  p-6 bg-gray-100 rounded-lg shadow-2xl
`

const Heading = tw.h1`
  text-2xl text-yellow-700 uppercase text-center
`

const Text = tw.p`
  text-xl text-gray-700 text-center
`

const Logos = tw.div`
  flex items-center justify-around mb-6 px-16
`

const Icon = tw.img`
  h-10
`

const Footer = tw.footer`
  mt-6 text-center
`

const SmallIcon = tw.img`
  inline-block h-6
`
const GoodBye = tw.p`text-yellow-700 text-2xl`
export default () => (
  <Wrapper>
    <SEO title="Home" />
    <Main style={{ width: "75vw" }}>
      <Heading>Asante (Thank you) for visiting Blessings Not Curses</Heading>
      <Text>
        Our website is currently under reconstruction. We apologize for the
        inconvenience.
        <br />
        Please check back soon. <br />
        For more information, <br />
        please call <p style={{ color: "blue" }}>314.382.0720</p> or email us at{" "}
        <br />
        <a href="mailto:blessingsnc@yahoo.com" style={{ color: "blue" }}>
          blessingsnc@yahoo.com
        </a>
        <GoodBye>Again, asante.</GoodBye>
      </Text>
      <Footer></Footer>
    </Main>
  </Wrapper>
)
